import React from 'react';
import TuppyCarousel from "./TuppyCarousel";

function App() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#101010",
        height: "100vh"
      }}>
    <TuppyCarousel />
    </div>
  );
}

export default App;
