import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

function shuffleArray(array: {}[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

function TuppyCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    goToIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    goToIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    let item = items[newIndex];
    if (!item.src) {
      item.src = item.dataSrc
    }
    setActiveIndex(newIndex);
  };

  const items = React.useMemo(() => {
    const out: {dataSrc: string, src: string|undefined, key: number}[] = [];
    for (let i = 0; i < 926; ++i) {
      let url = `https://tupper.dog/images/image-${i.toString().padStart(5, '0')}.jpg`;
      out.push({
        dataSrc: url,
        src: undefined,
        key: i,
      })
    }
    shuffleArray(out);
    out[0].src = out[0].dataSrc;
    return out;
  }, []);

  const slides = items.map((item) => {
    return (
      <CarouselItem
        key={item.key}
      >
        <img src={item.src} alt={"it's tuppy"}/>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      slide={false}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default TuppyCarousel;